@font-face {
    font-family: 'a Atmospheric';
    src: local('Atmospheric'), local('aAtmospheric'),
        url('aAtmospheric.woff2') format('woff2'),
        url('aAtmospheric.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

