@import url("./fonts/stylesheet.css");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1+Code&display=swap");
@import url("https://fonts.googleapis.com/css2?family=KoHo:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
